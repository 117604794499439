function CritiQLogo(props) {

	let width = props.width || 40;
	let height = props.height || 40;
	
	return (
		<svg width={width} height={height} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet">
			<path d="M31.945 21.139c-2.402 0-4.318.853-5.748 2.559s-2.146 4.259-2.146 7.658c0 3.976 1.025 6.824 3.072 8.543c1.326 1.115 2.916 1.673 4.766 1.673a7.8 7.8 0 0 0 2.01-.256a10.27 10.27 0 0 0 1.359-.493l-2.938-2.794l3.113-3.252l2.949 2.776c.459-.944.781-1.771.965-2.479c.289-1.063.432-2.303.432-3.72c0-3.254-.666-5.771-1.998-7.549c-1.332-1.778-3.277-2.666-5.836-2.666" fill="#cdd6f4">
			</path>
			<path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2m10.813 46l-3.486-3.307c-1.064.646-1.982 1.1-2.758 1.363c-1.301.436-2.857.652-4.67.652c-3.783 0-6.908-1.128-9.377-3.386c-2.996-2.716-4.494-6.706-4.494-11.968c0-5.303 1.535-9.312 4.607-12.028C25.141 17.109 28.258 16 31.984 16c3.754 0 6.904 1.175 9.449 3.524c2.939 2.716 4.41 6.516 4.41 11.397c0 2.585-.314 4.751-.945 6.496c-.51 1.664-1.262 3.046-2.256 4.146l3.33 3.137l-3.159 3.3" fill="#cdd6f4">
			</path>
		</svg>
	)
}

export default CritiQLogo;